import React from "react"
import axios from "axios"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Loader from "../components/loaderAnimation"

import styles from "../styles/emailconfirmation.module.css"

let settings = require("../settings")
const queryString = require("query-string")

class EmailConfirmation extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			display: "loader",
		}
	}

	componentDidMount() {
		const parsed = queryString.parse(this.props.location.search)

		var self = this

		if (parsed.link === undefined) {
			window.location.href = "/404"
			return
		} else {
			var requestData = {
				uniqueLink: parsed.link,
			}
			console.log("111111111")
			axios
				.post(settings.API_HOST + "user/emailconfirmation", requestData)
				.then(function (response) {
					if (response.status === 200) {
						self.setState({
							display: "success",
						})
					} else {
						self.setState({
							display: "failure",
						})
					}
				})
				.catch(function (error) {
					self.setState({
						display: "failure",
					})
				})
		}
	}

	render() {
		const display = () => {
			if (this.state.display === "loader") {
				return <Loader />
			} else if (this.state.display === "success") {
				return <h1>You have successfully confirmed your email address.</h1>
			} else {
				return (
					<React.Fragment>
						<h1>Verification link has expired.</h1>
						<h2>
							Please try again to sign in and request a new confirmation link.
						</h2>
					</React.Fragment>
				)
			}
		}

		return (
			<Layout isNavigationTransparent={true} isHomePage={false}>
				<SEO
					title="Email Address Verification"
					description="Poirot Email Address Verification"
					keywords={[
						`Poirot`,
						`Email Lookup Tool`,
						`Email Finder`,
						`Email Checker`,
					]}
				/>
				<div className={styles.headerBlockWrapper}>
					<div className="sectionWrapper1200">
						<div className={styles.headerBlock}>
							<div className={styles.emailConfirmationHeaderMessage}>
								{display()}
							</div>
						</div>
					</div>
				</div>
				<div className={styles.emailConfirmationEmptySpace}>
				</div>
			</Layout>
		)
	}
}

export default EmailConfirmation
